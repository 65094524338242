import { IReservation } from '../shared/places/interfaces'
import { BaseService } from './BaseService'

interface IPlaceRequest {
  data: any
}

interface IReservationRequest {
  data: {
    account: any,
    reservation: IReservation
  }
}

class PlaceService extends BaseService {
  async getPlace(accountSlug: string): Promise<IPlaceRequest> {
    return this.get(`/account/${accountSlug}`)
  }
  async getReservationPlace(reservationId: string): Promise<IReservationRequest> {
    return this.get(`/account/reservation/${reservationId}`)
  }
}

export default new PlaceService()
