import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faConciergeBell, faInfoCircle, faMapMarkerAlt, faPhone, faStar, faWifi, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';

import { IPlace } from '../../../shared/places/interfaces';
import { Modal } from '../../../stores/ModalStore';
import { useStores } from '../../../stores/hooks';

interface GeneralInfoProps {
  place: IPlace
}

function GeneralInfo ({place}: GeneralInfoProps) {
  const {modalStore} = useStores();
  const [isSticky, setIsSticky] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const offsetTop = containerRef.current.offsetTop;
        setIsSticky(window.scrollY > offsetTop);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const {general, recommendations, sections, services} = place;
  const {wifi, address, checkin, checkout, contact} = general;

  const openModal = (modal: Modal, data: any) => {
    modalStore.toggleModal(modal, data);
  };

  const scrollTo = (target: string) => {
    document.getElementById(target)?.scrollIntoView({behavior: 'smooth'});
  };

  const buildChip = (text: string, icon: IconDefinition, action: Function) => {
    return <>
    <IconContainer onClick={_ => action()}>
      <FontAwesomeIcon icon={icon}/>
      <Text>{text}</Text>
    </IconContainer>
    </>
  };

  const chips = [
    {
      valid: wifi?.network && wifi?.pass,
      title: 'Wi-Fi',
      icon: faWifi,
      action: () => openModal(Modal.PLACE_PAGE_WIFI, wifi),
    },
    {
      valid: checkin?.start || checkout?.start,
      title: 'Check-in / Checkout',
      icon: faClock,
      action: () => openModal(Modal.PLACE_PAGE_CHECKIN_OUT, {checkin, checkout}),
    },
    {
      valid: services.length,
      title: 'Serviços',
      icon: faConciergeBell,
      action: () => scrollTo('services-title'),
    },
    {
      valid: sections.length,
      title: 'Informações',
      icon: faInfoCircle,
      action: () => scrollTo('section-container'),
    },
    {
      valid: recommendations.length,
      title: 'Recomendações',
      icon: faStar,
      action: () => scrollTo('recommendations-title'),
    },
    {
      valid: contact?.name || contact?.phone,
      title: 'Contato',
      icon: faPhone,
      action: () => scrollTo('contact-container'),
    },
    {
      valid: address?.street || address?.city || address?.number || address?.neighborhood,
      title: 'Endereço',
      icon: faMapMarkerAlt,
      action: () => openModal(Modal.PLACE_PAGE_ADDRESS, address),
    },
  ];

  return (
    <>
      <RefDiv ref={containerRef} className={isSticky ? 'sticky' : ''}/>
      <GeneralContainer
        className={isSticky ? 'sticky' : ''}
      >
        <CotentWrapper>
          {chips.map(chip =>
            !!chip.valid && buildChip(
              chip.title,
              chip.icon,
              chip.action
            )
          )}
        </CotentWrapper>
      </GeneralContainer>
    </>
  );
}

const RefDiv = styled.div`
  &.sticky {
    height: 60px;
  }
`

const GeneralContainer = styled.div`
  max-width: 920px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 16px;
  transition: position 0.3s ease-in-out;

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0 16px;
  }
`;

const CotentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  padding: 8px 0;
`;

const Text = styled.p`
  margin: 0;
  color: #ee5048;
  font-weight: bold;
  font-size: .75em;
  white-space: nowrap;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  border: 2px solid #ee504873;
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
  padding: 4px 8px;
  margin-right: 12px;

  svg {
    font-size: 1em;
    color: #EE5048;
    padding-right: 4px;
  }
`;

export default GeneralInfo;