export function numberFormatter(num: number, digits: number = 0) {
  const si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "mil" },
    { value: 1E6, symbol: "mi" },
    { value: 1E9, symbol: "bi" },
    { value: 1E12, symbol: "tri" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const priceFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});