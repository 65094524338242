import { observable, action } from 'mobx'
import OrderService from '../services/OrderService'
import { IOrder, OrderStatus } from '../shared/places/interfaces'

class OrderStore {
  @observable
  orders: IOrder[] = []

  @observable
  totalOrders: number = 0

  private previousOrderIds: Set<number> = new Set();

  @action
  async getAllOrders(page = 1, perPage = 20, status?: OrderStatus, guestName?: string, propertyID?: number) {
    const response = await OrderService.getAllOrders(page, perPage, status, guestName, propertyID)

    this.orders = response.data.entries
    this.totalOrders = response.data.total_count
  }

  @action
  async createOrder(accountId: string, order: IOrder) {
    const response = await OrderService.createOrder(accountId, order)

    this.orders.push(response.data)
  }

  @action
  async updateOrder(id: number, order: IOrder) {
    const response = await OrderService.updateOrder(id, order)

    const updatedOrderIndex = this.orders.findIndex(order => order.id === id)

    if (updatedOrderIndex !== -1) {
      this.orders[updatedOrderIndex] = response.data
    }
  }

  @action
  async deleteOrder(id: number) {
    await OrderService.deleteOrder(id)

    this.orders = this.orders.filter(order => order.id !== id)
  }

  @action
  async pollForNewOrders() {
    setInterval(async () => {
      const response = await OrderService.getAllOrders(1, 100, OrderStatus.Pending);
      const currentOrderIds = new Set(response.data.entries.map(order => (order.id as number)));

      const newOrderIds = Array.from(currentOrderIds).filter(id => !this.previousOrderIds.has(id));
      if (newOrderIds.length > 0) {
        this.notifyNewOrders();
      }

      this.previousOrderIds = currentOrderIds;
    }, 5 * 60 * 1000); // Poll every 5 minutes  
  }

  private notifyNewOrders() {
    // Trigger notification logic (handled in Admin component)
    const event = new CustomEvent('newOrderNotification');
    window.dispatchEvent(event);
  }
}

const storeInstance = new OrderStore();
storeInstance.pollForNewOrders(); // Start polling
export default storeInstance
