import React from 'react'
import styled from 'styled-components'
import Textarea from '../Textarea'
import { observer } from 'mobx-react'

interface IGramTextarea {
  labelText: string
  name: string
  error: string | boolean
  value: any
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  type?: string
  subText?: string
  placeholder?: string
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
}

function GramTextarea(props: IGramTextarea) {
  return (
    <TextareaGroup>
      <TextareaLabel htmlFor={`${props.name}-textarea`}>{props.labelText}</TextareaLabel>
      {
        props.subText &&
        <TextareaSubLabelText>
          {props.subText}
        </TextareaSubLabelText>
      }
      <Textarea
        id={`${props.name}-textarea`}
        hasError={!!props.error}
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onFocus={props.onFocus}
      />
      {
        !!props.error &&
        <TextareaErrorLabelText>
          {props.error}
        </TextareaErrorLabelText>
      }
    </TextareaGroup>
  )
}

const TextareaGroup = styled.div`
  padding-bottom: 12px;

  &:last-of-type {
    padding-bottom: 24px;
  }
`

const TextareaLabel = styled.label`
  display: block;

  color: #666666;
  font-weight: 400;
  font-size: 16px;
`
const TextareaSubLabelText = styled.p`
  margin: 0;

  color: #3c3c3c;
  font-size: 12px;
`

const TextareaErrorLabelText = styled(TextareaSubLabelText)`
  margin-top: 4px;
  color: #ff6161;
`

export default observer(GramTextarea)
