export const dayOfWeek = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
]

export function formattedDate(date: Date) {
  
  const formattedDate = (new Date(date)).toLocaleString("pt-BR", {
    timeStyle: "short",
    dateStyle: "short"
  } as Intl.DateTimeFormatOptions)

  return formattedDate
}