import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { ICheckInAndOut } from '../../../../../../shared/places/interfaces'
import { formatTimeRange } from '../../../../../../util/time'

interface IProps {
  checkinAndOut: {
    checkin?: ICheckInAndOut
    checkout?: ICheckInAndOut
  }
}

function CheckInAndOut({checkinAndOut}: IProps) {

  const {checkin, checkout} = checkinAndOut

  console.log({...checkinAndOut})

  return (
    <ModalContainer onClick={(e) => e.stopPropagation()}>
      <Title>Horários de Check-in e Checkout</Title>
      {checkin && <SimpleContainer>
        <Subtitle>Check-in</Subtitle>
        <CheckinText>{formatTimeRange(checkin.start, checkin.end)}</CheckinText>
        {checkin.details ? <CheckinDetail>{checkin.details}</CheckinDetail> : <></>}
      </SimpleContainer>}
      {checkout && <SimpleContainer>
        <Subtitle>Checkout</Subtitle>
        <CheckinText>{formatTimeRange(checkout.start, checkout.end)}</CheckinText>
        {checkout.details ? <CheckinDetail>{checkout.details}</CheckinDetail> : <></>}
      </SimpleContainer>}
    </ModalContainer>
  )

}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  padding: 24px;
  margin: 0 8px;

  border-radius: 6px;

  background-color: #fff;
  color: #212121;

  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05),
              2px 4px 4px rgba(0, 0, 0, 0.05),
              4px 8px 8px rgba(0, 0, 0, 0.05),
              8px 16px 16px rgba(0, 0, 0, 0.05),
              16px 32px 32px rgba(0, 0, 0, 0.05);
`

const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  align-self: flex-start;
`

const Subtitle = styled.h3`
  margin: 0 0 16px;
  font-size: 1.25em;
`;

const CheckinText = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;

  font-size: 1em;
  font-weight: 500;
  color: #666666;

  :last-child {
    margin-bottom: 16px;
  }

  :first-child {
    margin-top: 16px;
  }
`


const CheckinDetail = styled(CheckinText)`
  font-size: 0.95em;
  color: #8b949b;
`

const SimpleContainer = styled.div`
  margin: 16px 0 0;
  padding-bottom: 0;
`;



export default observer(CheckInAndOut)
