import { IOrder, OrderStatus } from '../shared/places/interfaces'
import { BaseService } from './BaseService'

interface IOrderResponse {
  data: IOrder
}

interface IOrdersByReservationResponse {
  data: IOrder[]
}

interface IOrderListResponse {
  data: {
    entries: IOrder[]
    page: number
    per_page: number
    total_count: number
  }
}

class OrderService extends BaseService {
  async createOrder(accountId: string, data: IOrder): Promise<IOrderResponse> {
    return this.post(`${accountId}/order`, data)
  }

  async getAllOrders(page = 1, perPage = 10, status?: OrderStatus, guest_name?: string, property_id?: number): Promise<IOrderListResponse> {
    return this.get('/orders', {
      params: {
        page,
        per_page: perPage,
        status,
        guest_name,
        property_id
      }
    })
  }
  

  async getOrdersByReservation(reservationId: number): Promise<IOrdersByReservationResponse> {
    return this.get(`/orders/reservation/${reservationId}`)
  }

  async updateOrder(id: number, data: IOrder): Promise<IOrderResponse> {
    return this.put(`/orders/${id}`, data)
  }

  async deleteOrder(id: number): Promise<void> {
    return this.delete(`/orders/${id}`)
  }
}

export default new OrderService()