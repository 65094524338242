import { observable, action, computed } from 'mobx'

import { IService } from '../shared/places/interfaces'
import ServiceService from '../services/ServiceService'


class ServiceStore {
  @observable
  services: IService[] = []

  setServices(services: IService[]) {
    this.services = services
  }

  @computed
  get totalService(): number {
    return this.services.length
  }

  @computed
  get orderedService(): IService[] {
    return this.services.slice().sort(
      (a, b) =>
         a.order_view - b.order_view
    )
  }

  @action
  async createService(service: IService) {
    const response = await ServiceService.createService(service)

    this.services.push(response.data)
  }

  @action
  async updateService(id: number, service: IService) {
    const response = await ServiceService.updateService(id, service)

    const updatedServiceIndex = this.services.findIndex(service => service.id === id)

    this.services[updatedServiceIndex] = response.data
  }

  @action
  async deleteService(id: number) {
    await ServiceService.deleteService(id)

    this.services = this.services.filter(service => service.id !== id)
  }

  @action
  async reorder(startIndex: number, endIndex: number) {
    const result = Array.from(this.services)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    this.services = result.map((service, i) => {
      service.order_view = i
      return service
    })

    const positionList = this.services.reduce((prev, next) => {
      prev[next.id!.toString()] = next.order_view
      return prev
    }, {} as {[index: string]: number})

    return ServiceService.updateServiceOrder(positionList)
  }

  async upload(file: string, extension: string, name: string): Promise<string> {
    const fileName = `${name}.${extension}`

    const storageService = await import('../services/FirebaseStorageService')

    await storageService.default.uploadBase64(fileName, file)
    return await storageService.default.getItemUrl(fileName)
  }
}

const storeInstance = new ServiceStore()
export default storeInstance
