import React, { useState } from 'react';
import styled from 'styled-components'
import { IService } from '../../../shared/places/interfaces'
import { media } from '../../../util/breakpoints'
import { roundButtonStyle } from '../../common/Button'
import { useStores } from '../../../stores/hooks'
import { Modal } from '../../../stores/ModalStore'

interface ServicesProps {
  services: IService[]
}

function Services (props: ServicesProps) {
  const { modalStore } = useStores();
  const [visibleCount, setVisibleCount] = useState(5);

  if (!props.services.length) {
    return <></>;
  }

  const priceFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  const openServiceDetailsModal = (service: IService) => 
    modalStore.toggleModal(Modal.PLACE_PAGE_SERVICE_INFO, service);

  const openReserveService = (service: IService) => 
    modalStore.toggleModal(Modal.PLACE_PAGE_SERVICE_RESERVE, service);

  const loadMoreServices = () => {
    setVisibleCount(prevCount => prevCount + 5);
  };

  const sortedServices = props.services.sort((a, b) => a.order_view - b.order_view);
  const visibleServices = sortedServices.slice(0, visibleCount);

  return <ServiceWrapper>
      <Title id="services-title">Nossos Serviços</Title>
      {visibleServices.map((service, index) => (
        <ServiceCard key={service.id ?? `service-${index}`}>
          {service.image_url ? <ServiceImage src={service.image_url} alt={service.name} /> : null}
          <ServiceDetails>
            <ServiceTitle>{service.name}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
            <ServicePrice>{service.price ? priceFormatter.format(service.price) : 'Gratuito'}</ServicePrice>
            <ButtonContainer>
              <InfoButton onClick={_ => openServiceDetailsModal(service)}>Mais Informações</InfoButton>
              <ReserveButton onClick={_ => openReserveService(service)}>Reservar</ReserveButton>
            </ButtonContainer>
          </ServiceDetails>
        </ServiceCard>
      ))}
      {visibleCount < sortedServices.length && (
        <LoadMoreButton onClick={loadMoreServices}>Mostrar mais</LoadMoreButton>
      )}
  </ServiceWrapper>;
}

const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`

const Title = styled.h2`
  max-width: 920px;
  width: 100%;
  margin: 0 auto 24px;
  padding: 0 0 8px;

  font-size: 1.8em;

  ${media.deskLUp} {
    width: 920px;
    padding: 48px 0 8px;
  }

`;

const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 16px;
  padding: 16px;
  
  ${media.deskLUp} {
    flex-direction: row;
  }

  &:last-of-type {
    margin-bottom: 48px;
  }
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 160px;
  border-radius: 16px;
  object-fit: cover;

  ${media.mobMUp} {
    height: 200px;
  }

  ${media.deskLUp} {
    width: 220px;
    margin-right: 24px
  }
`;

const ServiceDetails = styled.div`
  flex: 1;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const ServiceTitle = styled.h3`
  margin: 0;
  font-size: 20px;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ServiceDescription = styled.p`
  margin: 0;
  font-size: 16px;
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ServicePrice = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #EE5048;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  

  ${media.deskLUp} {
    justify-content: flex-end;
  }
`

const ReserveButton = styled.button`
  ${roundButtonStyle}
  margin-bottom: 0;
  margin-top: 16px;
  font-size: 14px;
  padding: 8px;
  min-width: 150px;

  ${media.mobXS} {
    min-width: 110px;
  }
`;

const InfoButton = styled(ReserveButton)`
  background: 0;
  border: 2px solid #EE5048;
  color: #EE5048;

  
  ${media.deskLUp} {
    margin-right: 16px;
  }
`

const LoadMoreButton = styled.button`
  ${roundButtonStyle}
  background: 0;
  border: 2px solid #EE5048;
  color: #EE5048;
  margin: 16px auto 48px;
  font-size: 14px;
  padding: 8px;
  min-width: 230px;
  display: block;
`;

export default Services