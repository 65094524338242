import React, { useState } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { IOrder, IService, OrderStatus } from '../../../../../../shared/places/interfaces'
import { dayOfWeek } from '../../../../../../util/date'
import { formatTimeRange } from '../../../../../../util/time'
import GramInput from '../../../../GramInput'
import Button, { roundButtonStyle } from '../../../../Button'
import { generateWhatsAppLink } from '../../../../../../util/whatsapp-link'
import { useStores } from '../../../../../../stores/hooks'
import GramTextArea from '../../../../GramTextArea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

interface IProps {
  service: IService
}

interface ICustomerForm {
  name: string,
  room: string,
}

function ReserveService({service}: IProps) {
  const {placePageStore} = useStores()

  const priceFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });




  function GuestUserForm() {
    const [isLoading, setIsLoading] = useState(false)
    const [customerForm, setCustomerForm] = useState<ICustomerForm>({
      name: '',
      room: ''
    })
  
    const [formErrors, setFormErrors] = useState({
      name: false,
      room: false
    })
    
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value, name} = event.target

      setCustomerForm({...customerForm, [name]: value})
      setFormErrors({...formErrors, [name]: !value })
    }

    const validateForm = () => {
      if (!customerForm.name || !customerForm.room) {
        setFormErrors({
          name: !customerForm.name,
          room: !customerForm.room
        })
        return false
      } else {
        return true
      }
    }

    const handleSubmit = () => {
      if (validateForm()) {
        sendWhatsappMessage()
      }
    }

    const sendWhatsappMessage = () => {
      const message = `Oi! Me chamo *${customerForm.name}*, estou no *${customerForm.room}*.%0aGostaria de reservar o serviço *${service.name}*`
      const baseWhatsappLink = generateWhatsAppLink('11949653184', message)
      window.open(baseWhatsappLink, '_blank');
    }
  
    return <>
      <Text>Para reservar preencha os campos abaixo:</Text>
        <InputContainer>
          <GramInput
            labelText='Nome'
            error={(formErrors.name ? 'Preencha o seu nome' : false)}
            name='name'
            onChange={handleOnChange}
            value={customerForm.name}
          />
          <GramInput
            labelText='Quarto'
            error={(formErrors.room ? 'Preencha o seu quarto' : false)}
            name='room'
            onChange={handleOnChange}
            value={customerForm.room}
          />
        </InputContainer>
        <Button
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          Reservar
        </Button>
    </>
  }

  
  function ReservationUserForm() {
    const [isLoading, setIsLoading] = useState(false)
    const [orderSent, setOrderSent] = useState(false)
    const [notes, setNotes] = useState('')

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const {value, name} = event.target

      setNotes(value)
    }

    const handleReservationSubmit = () => {
      const {reservation} = placePageStore
      if (reservation) {
        const order: IOrder = {
          quantity: 1,
          status: OrderStatus.Pending,
          notes: notes,
          guest_name: reservation.guest_name,
          room_number: reservation.property?.name || '',
          service_id: service.id,
          reservation_id: reservation.id
        }
  
        setIsLoading(true)
        placePageStore.createOrder(placePageStore.place!.username!, order)
          .then(_ => {
            setIsLoading(false)
            setOrderSent(true)
          })
      }
    }
  
    return !orderSent ? <>
      <Text>Deseja adicionar alguma observação?</Text>
        <InputContainer>
          <GramTextArea
            labelText='Notas adicionais'
            error={false}
            name='notes'
            onChange={handleOnChange}
            value={notes}
          />
        </InputContainer>
        <Button
          isLoading={isLoading}
          onClick={handleReservationSubmit}
        >
          Reservar
        </Button>
    </> : <>
      <FontAwesomeIcon icon={faCheckCircle} color='green' size='4x'/>
      <br/>
      <Text>Pedido enviado!</Text>
      <Text>Aguarde o contato para confirmação.</Text>
    </>
  }


  return (
    <ModalContainer onClick={(e) => e.stopPropagation()}>
      <Title>{service.name}</Title>
      <Text>{service.description}</Text>
      {service.price && <Text><b>Preço unitário:</b> {priceFormatter.format(service.price)}</Text>}
      <br/>
      {placePageStore.reservation ? <ReservationUserForm/> : <GuestUserForm/> }
    </ModalContainer>
  )

}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  min-width: 300px;
  padding: 24px;
  margin: 0 8px;

  border-radius: 6px;

  background-color: #fff;
  color: #212121;

  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05),
              2px 4px 4px rgba(0, 0, 0, 0.05),
              4px 8px 8px rgba(0, 0, 0, 0.05),
              8px 16px 16px rgba(0, 0, 0, 0.05),
              16px 32px 32px rgba(0, 0, 0, 0.05);
`

const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  align-self: flex-start;
`

const Text = styled.p`
  width: 100%;
  margin: 4px;
`

const InputContainer = styled.div`
  width: 100%;
  margin-top: 18px;
`


export default observer(ReserveService)
