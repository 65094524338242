import { observable, action, computed } from 'mobx'

export enum Modal {
  SHARE_50OFF = 'SHARE_50OFF',
  QRCODE = 'QRCODE',
  PLACE_PAGE_SERVICE_INFO = 'SERVICE_INFO',
  PLACE_PAGE_SERVICE_RESERVE = 'SERVICE_RESERVE',
  PLACE_PAGE_WIFI = 'PLACE_PAGE_WIFI',
  PLACE_PAGE_ADDRESS = 'PLACE_PAGE_ADDRESS',
  PLACE_PAGE_CHECKIN_OUT = 'PLACE_PAGE_CHECKIN_OUT',
  ORDERS_BY_RESERVATION = 'ORDERS_BY_RESERVATION',
}

class ModalStore {
  @observable
  modals: {[key in Modal]: boolean} = {
    [Modal.SHARE_50OFF]: false,
    [Modal.QRCODE]: false,
    [Modal.PLACE_PAGE_SERVICE_INFO]: false,
    [Modal.PLACE_PAGE_SERVICE_RESERVE]: false,
    [Modal.PLACE_PAGE_WIFI]: false,
    [Modal.PLACE_PAGE_ADDRESS]: false,
    [Modal.PLACE_PAGE_CHECKIN_OUT]: false,
    [Modal.ORDERS_BY_RESERVATION]: false,
  }

  @observable
  modalProps: {[key in Modal]: any} = {
    [Modal.SHARE_50OFF]: null,
    [Modal.QRCODE]: null,
    [Modal.PLACE_PAGE_SERVICE_INFO]: null,
    [Modal.PLACE_PAGE_SERVICE_RESERVE]: null,
    [Modal.PLACE_PAGE_WIFI]: null,
    [Modal.PLACE_PAGE_ADDRESS]: null,
    [Modal.PLACE_PAGE_CHECKIN_OUT]: null,
    [Modal.ORDERS_BY_RESERVATION]: null,
  }

  @computed
  get current () {
    return Object
      .keys(this.modals)
      .find(k => !!this.modals[k as Modal])
  }

  @computed
  get currentProps(): any {
    return this.current ? this.modalProps[this.current as Modal] : null
  }

  @action
  toggleModal(modal: Modal, props?: any) {
    this.modals[modal] = !this.modals[modal]

    if (props) {
      this.modalProps[modal] = props
    }

    Object
      .keys(this.modals)
      .forEach(
        (k) => k !== modal && (this.modals[k as Modal] = false)
      )
  }

  @action
  closeAll() {
    Object
      .keys(this.modals)
      .forEach((k) => this.modals[k as Modal] = false)
  }
}

const storeInstance = new ModalStore()
export default storeInstance
