import React, { TextareaHTMLAttributes } from 'react'
import styled from 'styled-components'

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasError?: boolean
}

function TextArea(props: ITextAreaProps) {
  return (
    <StyledTextArea {...props}/>
  )
}

const StyledTextArea = styled.textarea<ITextAreaProps>`
  box-sizing: border-box;
  height: 80px;
  width: 100%;
  margin-top: 4px;
  padding: 4px 6px;

  border: 1px solid ${props => props.hasError ? '#ff6161' : '#D9D9D9'};
  border-radius: 8px;

  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;

  resize: vertical;

  outline: none;
`

export default TextArea
