import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { IAddress } from '../../../../../../shared/places/interfaces'
import { formatAddress } from '../../../../../../util/format-address'
import { MapPinIconLink } from '../../../../../../shared/MapPinIconLink'

interface IProps {
  address: IAddress
}

function Address({address}: IProps) {

  return (
    <ModalContainer onClick={(e) => e.stopPropagation()}>
      <Title>Endereço</Title>
        <SimpleContainer>
          <AddressText><span>{formatAddress(address)}</span><MapPinIconLink address={formatAddress(address)}/></AddressText>
        </SimpleContainer>
    </ModalContainer>
  )

}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  min-width: 300px;
  padding: 24px;
  margin: 0 8px;

  border-radius: 6px;

  background-color: #fff;
  color: #212121;

  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05),
              2px 4px 4px rgba(0, 0, 0, 0.05),
              4px 8px 8px rgba(0, 0, 0, 0.05),
              8px 16px 16px rgba(0, 0, 0, 0.05),
              16px 32px 32px rgba(0, 0, 0, 0.05);
`

const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  align-self: flex-start;
`

const AddressText = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1em;
  font-weight: 500;
  color: #666666;
`;

const SimpleContainer = styled.div`
  margin: 16px 0;
  padding-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default observer(Address)
