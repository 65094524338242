export const formatTimeRange = (start: string, end?: string, checkout?: boolean) => {
  return end
    ? `Entre ${start} e ${end}`
    : checkout
      ? `Até as ${start}`
      : `A partir de ${start}`
}

export const generateTimeOptions = () => {
  const times = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 15) {
      const hour = h.toString().padStart(2, "0");
      const minute = m.toString().padStart(2, "0");
      times.push(`${hour}:${minute}`);
    }
  }
  return times;
}