import React, { useState } from 'react'

import { faClone } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { RoundIconSyle } from '../styles';

interface IProps {
  text: string
}

export const CopyIcon = ({text}: IProps) => {

  const [stateIcon, setStateIcon] = useState(faClone)

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(
      () => {
        setStateIcon(faCheck)

        setTimeout(() => {
          setStateIcon(faClone)
        }, 4000)
      },
      () => {
        setStateIcon(faTimes)
      }
    );
  };

  return <>
    <RoundIcon onClick={handleCopy}>
      <FontAwesomeIcon icon={stateIcon} color='#EE5048'/>
    </RoundIcon>
  </>
}


const RoundIcon = styled.span`
  ${RoundIconSyle}
`