
import { IProperty, PropertyType } from '../shared/places/interfaces'
import { BaseService } from './BaseService'

interface IPropertyResponse {
  data: IProperty
}

interface IPropertyListResponse {
  data: IProperty[]
}

interface IUpdatePropertyOrderResponse {
  data: number[]
}

interface ICreateBulkProperties {
  type: PropertyType,
  quantity: number
}

class PropertyService extends BaseService {

  async createProperty(data: IProperty): Promise<IPropertyResponse> {
    return this.post('/properties', data)
  }

  async bulkCreateProperty(data: ICreateBulkProperties): Promise<IPropertyListResponse> {
    return this.post('/properties/bulk', data)
  }

  async getAllProperties(): Promise<IPropertyListResponse> {
    return this.get('/properties')
  }

  async getProperty(id: number): Promise<IPropertyResponse> {
    return this.get(`/properties/${id}`)
  }

  async updateProperty(id: number, data: IProperty): Promise<IPropertyResponse> {
    return this.put(`/properties/${id}`, data)
  }

  async deleteProperty(id: number): Promise<IUpdatePropertyOrderResponse> {
    return this.delete(`/properties/${id}`)
  }
}

export default new PropertyService()
