
import { IService } from '../shared/places/interfaces'
import { BaseService } from './BaseService'

interface ICreateServiceRequest {
  data: IService
}

interface IUpdateServiceOrderRequest {
  data: number[]
}

interface IUpdateServiceOrderDTO {
  [id: string]: number
}


class ServiceService extends BaseService {

  async createService(data: IService): Promise<ICreateServiceRequest> {
    return this.post('/service', data)
  }

  async updateService(id: number, data: IService): Promise<ICreateServiceRequest> {
    return this.put(`/service/${id}`, data)
  }

  async deleteService(id: number): Promise<IUpdateServiceOrderRequest> {
    return this.delete(`/service/${id}`)
  }

  async updateServiceOrder(data: IUpdateServiceOrderDTO): Promise<IUpdateServiceOrderRequest> {
    return this.post(`/service/update-order`, data)
  }
}

export default new ServiceService()
