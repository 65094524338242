import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'

import { Modal } from '../../../stores/ModalStore'
import { useStores } from '../../../stores/hooks'
import ShareCouponModal from './ShareCouponModal'
import QrCodeModal from './QrCodeModal'
import ServiceInfoModal from './PlacePage/Service/ServiceInfoModal'
import OrdersByReservationModal from './Admin/Reservation/OrdersByReservationModal'
import { IAddress, IService, IWifi } from '../../../shared/places/interfaces'
import ReserveService from './PlacePage/Service/ReserveService'
import Wifi from './PlacePage/GeneralInfo/Wifi'
import Address from './PlacePage/GeneralInfo/Address'
import CheckInAndOut from './PlacePage/GeneralInfo/CheckInAndOut'
import { media } from '../../../util/breakpoints'

function ApplicationModal() {
  const { modalStore } = useStores()

  const ModalWrapper = (props: {children: React.ReactNode}) => {
    return <ModalOverlay onClick={e => modalStore.closeAll()}>
      <ModalContentWrapper>
        <CloseButton>fechar</CloseButton>
        {props.children}
      </ModalContentWrapper>
    </ModalOverlay>
  }

  switch (modalStore.current) {
    case Modal.SHARE_50OFF:
      return <ModalWrapper><ShareCouponModal/></ModalWrapper>
    case Modal.QRCODE:
      return <ModalWrapper><QrCodeModal/></ModalWrapper>
    case Modal.PLACE_PAGE_SERVICE_INFO:
      return <ModalWrapper><ServiceInfoModal service={(modalStore.currentProps as IService)}/></ModalWrapper>
    case Modal.PLACE_PAGE_SERVICE_RESERVE:
      return <ModalWrapper><ReserveService service={(modalStore.currentProps as IService)}/></ModalWrapper>
    case Modal.PLACE_PAGE_WIFI:
      return <ModalWrapper><Wifi wifi={(modalStore.currentProps as IWifi)}/></ModalWrapper>
    case Modal.PLACE_PAGE_ADDRESS:
      return <ModalWrapper><Address address={(modalStore.currentProps as IAddress)}/></ModalWrapper>
    case Modal.PLACE_PAGE_CHECKIN_OUT:
      return <ModalWrapper><CheckInAndOut checkinAndOut={(modalStore.currentProps as any)}/></ModalWrapper>
    case Modal.ORDERS_BY_RESERVATION:
      return <ModalWrapper><OrdersByReservationModal reservationId={(modalStore.currentProps as number)}/></ModalWrapper>
  }


  return <></>
}

const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0,0,0,0.3);
`

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.upToTabM} {
    width: 100%;
  }
`

const CloseButton = styled.span`
  align-self: flex-end;
  padding: 8px;

  color: white;
  font-weight: 600;

  cursor: pointer;
`

export default observer(ApplicationModal)
