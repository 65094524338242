import React from 'react'
import accountStore from '../AccountStore'
import modalStore from '../ModalStore'
import linkStore from '../LinkStore'
import eventStore from '../EventStore'
import sectionStore from '../SectionStore'
import placePageStore from '../PlacePageStore'
import recommendationStore from '../RecommendationStore'
import serviceStore from '../ServiceStore'
import propertyStore from '../PropertyStore'
import reservationStore from '../ReservationStore'
import orderStore from '../OrderStore'


export const storesContext = React.createContext({
  accountStore,
  modalStore,
  linkStore,
  eventStore,
  sectionStore,
  placePageStore,
  recommendationStore,
  serviceStore,
  propertyStore,
  reservationStore,
  orderStore,
})