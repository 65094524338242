export interface IPlace {
  pageId: string;
  name: string;
  username?: string;
  description: string;
  contact: IContact;
  general: IGeneral;
  sections: ISection[];
  recommendations: IRecommendation[];
  services: IService[];
  image_url?: string;
}

export interface ICheckInAndOut {
  start: string;
  end?: string;
  details?: string;
}

export interface IContact {
  name: string;
  phone: string;
  whatsapp: string;
  profileImage?: string;
}

export interface IGeneral {
  address?: IAddress;
  wifi?: IWifi;
  checkin?: ICheckInAndOut;
  checkout?: ICheckInAndOut;
  contact?: IContact;
}

export interface IWifi {
  network: string;
  pass: string;
}

export interface ISection {
  title: string;
  content: string;
  order_view: number;
  id?: number;
}

export interface IRecommendation {
  title: string;
  type: string;
  description: string;
  order_view: number;
  id?: number;
  phone?: string | null;
  whatsapp?: string | null;
  address?: string | null;
  site?: string | null;
  image_url?: string | null;
  rating?: number | null;
  external_id?: string | null;
}

export interface IRecommendationSuggestion {
  name: string;
  description: string;
  type: string;
  address: string;
  phone_number: string;
  website: string;
  rating: number;
  num_reviews: number;
  price_level: number;
  photo: string;
  place_id: string;
}

export interface IAddress {
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  postalCode: string;
  complement?: string | null;
}

export interface IGeneralItem {
  key: string
  value: any
}

export enum WeekDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export type IAvailability = {
  [key in WeekDay]?: IAvailabilityItem
}

export interface IAvailabilityItem {
  startTime?: string
  endTime?: string
  is24hours?: boolean
  enabled?: boolean
}

export interface IServiceCategory {
  id?: number;
  name: string;
  account_id?: number;
  inserted_at?: string;
  updated_at?: string;
}

export interface IService {
  id?: number
  name: string
  description?: string
  price?: number
  discounted_price?: number
  availability?: IAvailability
  service_category_id?: number
  service_category?: IServiceCategory
  image_url?: string
  order_view: number
}

export type PropertyType = 'room' | 'property'

export interface IProperty {
  id?: number
  name: string
  property_type: PropertyType
  description?: string
}

export interface IReservation {
  id?: number
  uid?: string
  check_in: Date
  check_out: Date
  guest_name: string
  guest_email?: string
  guest_phone?: string
  property_id?: number
  property?: IProperty
}

export enum OrderStatus {
  Pending = 'pending',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
}

export interface IOrder {
  id?: number
  quantity: number
  total_price?: number | null
  status: OrderStatus
  notes?: string | null
  guest_name: string
  room_number: string
  service_id?: number | null
  service?: IService
  reservation_id?: number | null
  reservation?: IReservation
  account_id?: number
  inserted_at?: Date
  updated_at?: Date
}

export interface DashboardData {
  revenue: number
  executed_services: number
  pending_services: number
  occupancy_rate: number
  occupied_rooms: number
  total_rooms: number
  guests_served: number
  avg_ticket: number
}