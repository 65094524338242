import React from 'react'
import styled from 'styled-components'
import { IContact } from '../../../shared/places/interfaces';
import { media } from '../../../util/breakpoints';
import { generateWhatsAppLink } from '../../../util/whatsapp-link';

interface ContactProps {
  contact?: IContact
}

function Contact (props: ContactProps) {
  if (!props.contact) {
    return <></>
  }

  const { name, phone, whatsapp, profileImage } = props.contact

  if (!phone && !whatsapp) {
    return <></>
  }

  return <>
    <ContactContainer id="contact-container">
      <CotentWrapper>
        <Headline>
          Ficou alguma dúvida?<br/>Entre em contato:
        </Headline>
        <ContactInfo>
          {profileImage && <ProfileImage src={profileImage} alt={name} />}
          <ContactName>{name}</ContactName>
          <ContactLinks>
            <ContactLink href={`tel:${phone}`} target="_blank">Telefone</ContactLink>
            {whatsapp && <ContactLink href={generateWhatsAppLink(whatsapp)} target="_blank">WhatsApp</ContactLink>}
          </ContactLinks>
        </ContactInfo>
      </CotentWrapper>
    </ContactContainer>
  </>
}

const CotentWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  text-align: center;

  ${media.deskLUp} {
    flex-direction: row;
    justify-content: space-evenly;
  }
`

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  
  padding: 30px;
  margin-bottom: 62px;
  
  background-color: #f5f5f5;
  border-radius: 12px;
`;

const Headline = styled.p`
  width: 100%;
  padding-bottom: 16px;

  font-size: 22px;
  font-weight: bold;

  border-bottom: 1px solid #D9D9D9;

  ${media.deskLUp} {
    display: flex;
    align-content: center;
    align-items: center;

    width: auto;
    height: 100%;
    padding-right: 80px;

    font-size: 32px;
    border-bottom: 0;
    border-right: 1px solid #D9D9D9;
  }
`

const ProfileImage = styled.img`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  margin-bottom: 16px;
  object-fit: cover;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactName = styled.h2`
  margin: 0 0 8px;
  font-size: 1.5em;
`;

const ContactLink = styled.a`
  display: inline-block;
  min-width: 110px;
  margin: 4px 4px 4px 0;
  padding: 6px 14px;
  border-radius: 32px;
  
  text-align: center;
  color: #EE5048;
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 500;

  border: 2px solid #EE5048;

  background-color: transparent;
  

  transition: background-color ease 0.3s;

  &:hover {
    background-color: #FDCFCC;
  }
`;

const ContactLinks = styled.div``

export default Contact