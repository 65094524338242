import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { IOrder, OrderStatus } from '../../../../../../shared/places/interfaces'
import { priceFormatter } from '../../../../../../util/number-formatter'
import OrderService from '../../../../../../services/OrderService'
import LoadingWrapper from '../../../../LoadingWrapper'

interface IProps {
  reservationId: number
}

// Mapeamento de status para português
const statusTranslations: { [key in OrderStatus]: string } = {
  [OrderStatus.Pending]: 'Pendente',
  [OrderStatus.Confirmed]: 'Confirmado',
  [OrderStatus.Cancelled]: 'Cancelado'
}

function OrdersByReservationModal({ reservationId }: IProps) {
  const [isLoading, setIsLoading] = useState(true)
  const [orders, setOrders] = useState<IOrder[]>([])

  useEffect(() => {
    OrderService.getOrdersByReservation(reservationId)
      .then(response => {
        setOrders(response.data)
      })
      .then(() => setIsLoading(false))
  }, [reservationId])

  if (!reservationId) return <></>

  // Cálculo dos indicadores
  const confirmedOrders = orders.filter(order => order.status === OrderStatus.Confirmed && order.total_price)
  const totalRevenue = confirmedOrders.reduce((acc, order) => acc + (order.total_price || 0), 0)
  const pendingOrdersCount = orders.filter(order => order.status === OrderStatus.Pending).length

  return (
    <ModalContainer onClick={(e) => e.stopPropagation()}>
      {isLoading && <LoadingWrapper isLoading={isLoading} />}
      <Title>Pedidos</Title>
      
      <SummaryContainer>
        <SummaryItem>
          <SummaryTitle>Faturamento Total</SummaryTitle>
          <SummaryValue>{priceFormatter.format(totalRevenue)}</SummaryValue>
        </SummaryItem>
        <SummaryItem>
          <SummaryTitle>Pedidos Confirmados</SummaryTitle>
          <SummaryValue>{confirmedOrders.length}</SummaryValue>
        </SummaryItem>
        <SummaryItem>
          <SummaryTitle>Pedidos Pendentes</SummaryTitle>
          <SummaryValue>{pendingOrdersCount}</SummaryValue>
        </SummaryItem>
      </SummaryContainer>
      
      {orders.length > 0 ? (
        orders.map(order => (
          <OrderRow key={`order-${order.id}`}>
            <OrderDetail>
              <OrderServiceName>{order.service?.name || 'Serviço não definido'}</OrderServiceName>
              <OrderInfo>Quarto: {order.room_number}</OrderInfo>
              <OrderInfo>Quantidade: {order.quantity}</OrderInfo>
              <OrderInfo>Total: {priceFormatter.format(order.total_price || 0)}</OrderInfo>
              <OrderInfo>Status: {statusTranslations[order.status] || order.status}</OrderInfo>
              <OrderInfo>Data: {new Date(order.inserted_at!).toLocaleDateString('pt-BR')}</OrderInfo>
            </OrderDetail>
          </OrderRow>
        ))
      ) : (
        !isLoading && <NoOrdersMessage>Nenhum pedido registrado para esta reserva.</NoOrdersMessage>
      )}
      
    </ModalContainer>
  )
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  min-width: 300px;
  min-height: 300px;
  padding: 24px;
  margin: 0 8px;
  overflow-y: auto;
  max-height: 75vh;
  border-radius: 6px;
  background-color: #fff;
  color: #212121;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05),
              2px 4px 4px rgba(0, 0, 0, 0.05),
              4px 8px 8px rgba(0, 0, 0, 0.05),
              8px 16px 16px rgba(0, 0, 0, 0.05),
              16px 32px 32px rgba(0, 0, 0, 0.05);
`

const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  align-self: flex-start;
  margin-bottom: 12px;
`

const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
`

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SummaryTitle = styled.span`
  font-size: 14px;
  color: #555;
  margin-bottom: 4px;
`

const SummaryValue = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #333;
`

const OrderRow = styled.div`
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
`

const OrderDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const OrderServiceName = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #333;
`

const OrderInfo = styled.span`
  font-size: 14px;
  color: #555;
`

const NoOrdersMessage = styled.p`
  width: 100%;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  color: #888;
  margin-top: 20px;
`

export default observer(OrdersByReservationModal)