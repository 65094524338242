import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import Hero from './Hero';
import Contact from './Contact';
import GeneralInfo from './GeneralInfo';
import Sections from './Section';
import Recommendations from './Recommendations';
import { IPlace } from '../../shared/places/interfaces';
import { useStores } from '../../stores/hooks';
import NotFound from '../NotFound';
import LoadingWrapper from '../common/LoadingWrapper';
import Helmet from 'react-helmet';
import { media } from '../../util/breakpoints';
import Services from './Services';

interface PageParams {
  pageType: 'r' | 'p',
  pageId: string,
  guestSlug?: string
}


function PlacePage() {
  const {pageType, pageId} = useParams<PageParams>()

  const {placePageStore} = useStores()

  const [place, setPlace] = useState<IPlace>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchPlaceData = async (pageType: 'r' | 'p', pageId: string) => {
      try {
        if (pageType === 'r') {
          const data = await placePageStore.getReservationPlaceData(pageId)
          setPlace(data)
        } else if (pageType === 'p') {
          const data = await placePageStore.getPlaceData(pageId)
          setPlace(data)
        }
 
        setIsLoading(false)
      } catch (error) {
        console.log(error)
        setIsLoading(false)
        return null
      }
    }

    fetchPlaceData(pageType, pageId)
  }, [pageId, pageType, placePageStore])

  if (place) {
    const placeDetails = {
      image: place.image_url || 'https://placehold.co/1200x700/FCDCDA/EE5048?text=...',
      title: place.name,
      description: place.description,
      guestName: pageType === 'r' ? placePageStore.reservation?.guest_name : undefined
    }
  
    return (
      <>
        <Helmet>
          <title>{place.name} | Hostli</title>
        </Helmet>
        <Page>
          <Hero placeDetails={placeDetails}/>
          <Wrapper>
            <GeneralInfo place={place}/>
            <Services services={place.services}/>
            <Sections sections={place.sections}/>
            <Recommendations recommendations={place.recommendations}/>
            <Contact contact={place.general.contact}/>
          </Wrapper>
        </Page>
      </>
    )
  } else {
    if (isLoading) {
      return <LoadingWrapper isLoading={isLoading}><Page/></LoadingWrapper>
    } else {
      return <NotFound term={pageId}/>
    }
  }
}

const Page = styled.div`
  min-height: 100vh;
`

const Wrapper = styled.div`
  max-width: 952px;
  margin: 0 16px;
  padding-bottom: 16px;
  padding-top: 16px;

  ${media.deskLUp} {
    margin: 0 auto;
  }
`

export default PlacePage
