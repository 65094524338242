import { action, observable } from 'mobx'

import PlaceService from '../services/PlaceService'
import { IOrder, IPlace, IReservation } from '../shared/places/interfaces'
import OrderService from '../services/OrderService'

interface IGeneralItem {
  key: string
  value: any
}

class PlacePageStore {

  @observable
  reservation?: IReservation

  @observable
  place?: IPlace

  @action
  async createOrder(accountId: string, order: IOrder) {
    try {
      const response = await OrderService.createOrder(accountId, order)
      return response.data
    } catch (error) {
      throw error
    }
  }

  @action
  async getPlaceData(placeId: string) {
    try {
      const response = await PlaceService.getPlace(placeId)
      const formattedData = this.formatResponse(response.data)
      this.place = formattedData
      return formattedData
    } catch (error) {
      throw error
    }
  }

  @action
  async getReservationPlaceData(reservationId: string) {
    try {
      const response = await PlaceService.getReservationPlace(reservationId)
      this.reservation = response.data.reservation
      const formattedData = this.formatResponse(response.data.account)
      this.place = formattedData
      return formattedData
    } catch (error) {
      throw error
    }
  }

  formatResponse(data: any) {
    const wifiData = data.general.find((i: IGeneralItem) => i.key === 'wifi')
    const wifi = wifiData ? wifiData.value : undefined
    const addressData = data.general.find((i: IGeneralItem) => i.key === 'address')
    const address = addressData ? addressData.value : undefined
    const checkinData = data.general.find((i: IGeneralItem) => i.key === 'checkin')
    const checkin = checkinData ? checkinData.value : undefined
    const checkoutData = data.general.find((i: IGeneralItem) => i.key === 'checkout')
    const checkout = checkoutData ? checkoutData.value : undefined
    const contactData = data.general.find((i: IGeneralItem) => i.key === 'contact')
    const contact = contactData ? contactData.value : undefined

    const general = {wifi, address, checkin, checkout, contact}

    return {...data, general}
  }
}


const storeInstance = new PlacePageStore()
export default storeInstance
