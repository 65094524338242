
import { IReservation } from '../shared/places/interfaces'
import { BaseService } from './BaseService'

interface IReservationResponse {
  data: IReservation
}

interface IReservationListResponse {
  data: {
    entries: IReservation[]
    page: number
    per_page: number
    total_count: number
  }
}

interface IUpdateReservationOrderResponse {
  data: number[]
}

class ReservationService extends BaseService {

  async createReservation(data: IReservation): Promise<IReservationResponse> {
    return this.post('/reservations', data)
  }

  async getAllReservations(page = 1, perPage = 10): Promise<IReservationListResponse> {
    return this.get('/reservations', {
      params: {
        page,
        per_page: perPage
      }
    })
  }

  async getReservation(id: number): Promise<IReservationResponse> {
    return this.get(`/reservations/${id}`)
  }

  async updateReservation(id: number, data: IReservation): Promise<IReservationResponse> {
    return this.put(`/reservations/${id}`, data)
  }

  async deleteReservation(id: number): Promise<IUpdateReservationOrderResponse> {
    return this.delete(`/reservations/${id}`)
  }
}

export default new ReservationService()
