import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { IWifi } from '../../../../../../shared/places/interfaces'
import { CopyIcon } from '../../../../../../shared/CopyIcon'

interface IProps {
  wifi: IWifi
}

function Wifi({wifi}: IProps) {

  return (
    <ModalContainer onClick={(e) => e.stopPropagation()}>
      <Title>Wi-Fi</Title>
      <SimpleContainer>
        <Subtitle>Informações para acesso Wi-Fi</Subtitle>
        <WifiCredentialContainer>
          <WifiCredential><b>Rede:</b> <span>{wifi.network} <CopyIcon text={wifi.network}/></span></WifiCredential>
          <WifiCredential><b>Senha:</b> <span>{wifi.pass} <CopyIcon text={wifi.pass}/></span></WifiCredential>
        </WifiCredentialContainer>
      </SimpleContainer>
    </ModalContainer>
  )

}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  min-width: 300px;
  padding: 24px;
  margin: 0 8px;

  border-radius: 6px;

  background-color: #fff;
  color: #212121;

  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05),
              2px 4px 4px rgba(0, 0, 0, 0.05),
              4px 8px 8px rgba(0, 0, 0, 0.05),
              8px 16px 16px rgba(0, 0, 0, 0.05),
              16px 32px 32px rgba(0, 0, 0, 0.05);
`

const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  align-self: flex-start;
`

const Subtitle = styled.p`
  margin: 0 0 16px;
  font-size: 1.1em;
`;

const WifiCredentialContainer = styled.div`
  width: 100%;
`

const WifiCredential = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin: 12px 0;

  font-size: 1em;
`;

const SimpleContainer = styled.div`
  width: 100%;

  margin: 16px 0;
  padding-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;



export default observer(Wifi)
