import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { IAvailability, IAvailabilityItem, IService, WeekDay } from '../../../../../../shared/places/interfaces'
import { dayOfWeek } from '../../../../../../util/date'
import { formatTimeRange } from '../../../../../../util/time'
import { media } from '../../../../../../util/breakpoints'
import { priceFormatter } from '../../../../../../util/number-formatter'

interface IProps {
  service: IService
}

function ServiceInfoModal({service}: IProps) {

   if (!service.availability)
     return <></>;

  const today = new Date()

  const buildAvailabilityText = (availability: IAvailabilityItem) => {
    if (!availability.enabled)
      return 'Indisponível';

    if (availability.is24hours)
      return '24 horas';

    if (availability.startTime)
      return formatTimeRange(availability.startTime, availability.endTime)

    return 'Indisponível'
  }

  return (
    <ModalContainer onClick={(e) => e.stopPropagation()}>
      {service.image_url && <Image src={service.image_url}/>}
      <Title>{service.name}</Title>
      <Text>{service.description}</Text>
      {service.price && <Text><b>Preço:</b> {priceFormatter.format(service.price)}</Text>}

      {service.availability && <><SubTitle>Disponibilidade</SubTitle>
      <Text>O serviço está disponível nos seguintes horários:</Text>
      { dayOfWeek.map((day, i) => {
        const a = service.availability![i as WeekDay]

        if(!a) 
          return;

        return <>
          <Availability key={`availability-${i}`} className={today.getDay() === i ? 'bold' : ''}>
            <p>{dayOfWeek[i]}</p>
            <p>{buildAvailabilityText(a)}</p>
          </Availability>
        </>
      })}</>}
  
    </ModalContainer>
  )

}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  padding: 24px;
  margin: 0 8px;

  overflow-y: auto;
  max-height: 75vh;

  border-radius: 6px;

  background-color: #fff;
  color: #212121;

  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05),
              2px 4px 4px rgba(0, 0, 0, 0.05),
              4px 8px 8px rgba(0, 0, 0, 0.05),
              8px 16px 16px rgba(0, 0, 0, 0.05),
              16px 32px 32px rgba(0, 0, 0, 0.05);
`

const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  align-self: flex-start;
  margin-bottom: 12px;
`

const SubTitle = styled.p`
  font-size: 18px;
  margin: 26px 0 12px;
  font-weight: 600;
  align-self: flex-start;
`

const Text = styled.p`
  width: 100%;
  margin: 6px 0;
`

const Image = styled.img`
  width: 100%;
  height: 160px;
  border-radius: 16px;
  object-fit: cover;

  ${media.mobMUp} {
    height: 200px;
  }
`;

const Availability = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  p {
    margin: 4px 0;
  }

  &.bold {
    font-weight: bold;
  }
`

export default observer(ServiceInfoModal)
