import { observable, action, computed } from 'mobx'
import { IReservation } from '../shared/places/interfaces'
import ReservationService from '../services/ReservationService'


class ReservationStore {
  @observable
  reservations: IReservation[] = []
  
  @observable
  totaReservations: number = 0

  setReservations(reservations: IReservation[]) {
    this.reservations = reservations
  }

  @action
  async getAllReservations(page = 1, perPage = 20) {
    const response = await ReservationService.getAllReservations(page, perPage)

    this.reservations = response.data.entries
    this.totaReservations = response.data.total_count
  }

  @action
  async createReservation(reservation: IReservation) {
    const response = await ReservationService.createReservation(reservation)

    this.reservations.push(response.data)
  }

  @action
  async updateReservation(id: number, reservation: IReservation) {
    const response = await ReservationService.updateReservation(id, reservation)

    const updatedReservationIndex = this.reservations.findIndex(reservation => reservation.id === id)

    this.reservations[updatedReservationIndex] = response.data
  }

  @action
  async deleteReservation(id: number) {
    await ReservationService.deleteReservation(id)

    this.reservations = this.reservations.filter(reservation => reservation.id !== id)
  }
}

const storeInstance = new ReservationStore()
export default storeInstance
