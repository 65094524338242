import React from 'react'
import styled from 'styled-components'
import Input from '../Input'
import { observer } from 'mobx-react'

interface IGramInput {
  name: string
  error: string | boolean
  value: any
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  labelText?: string
  disabled?: boolean
  type?: string
  step?: string
  min?: string
  max?: string
  subText?: string
  placeholder?: string
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
}

function GramInput(props: IGramInput) {
  return (
    <InputGroup>
      {props.labelText && <InputLabel htmlFor={`${props.name}-input`}>{props.labelText}</InputLabel>}
      {
        props.subText &&
        <InputSubLabelText>
          {props.subText}
        </InputSubLabelText>
      }
      <Input
        id={`${props.name}-input`}
        hasError={!!props.error}
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        step={props.step}
        min={props.min}
        max={props.max}
        placeholder={props.placeholder}
        onFocus={props.onFocus}
        type={props.type}
        disabled={props.disabled}
      />
      {
        !!props.error &&
        <InputErrorLabelText>
          {props.error}
        </InputErrorLabelText>
      }
    </InputGroup>
  )
}

const InputGroup = styled.div`
  padding-bottom: 12px;

  &:last-child {
    padding-bottom: 24px;
  }
`

const InputLabel = styled.label`
  display: block;

  color: #666666;
  font-weight: 400;
  font-size: 16px;
`
const InputSubLabelText = styled.p`
  margin: 0;

  color: #3c3c3c;
  font-size: 12px;
`

const InputErrorLabelText = styled(InputSubLabelText)`
  margin-top: 4px;
  color: #ff6161;
`

export default observer(GramInput)
