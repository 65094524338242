import { observable, action, computed } from 'mobx'
import { IProperty, PropertyType } from '../shared/places/interfaces'
import PropertyService from '../services/PropertyService'


class PropertyStore {
  @observable
  properties: IProperty[] = []

  setProperties(properties: IProperty[]) {
    this.properties = properties
  }

  @computed
  get totalProperty(): number {
    return this.properties.length
  }

  @computed
  get sortedProperties(): IProperty[] {
    return this.properties.slice().sort((a, b) => a.name.localeCompare(b.name))
  }


  @action
  async getAllProperties() {
    const response = await PropertyService.getAllProperties()

    this.properties = response.data
  }

  @action
  async createProperty(property: IProperty) {
    const response = await PropertyService.createProperty(property)

    this.properties.push(response.data)
  }

  @action
  async bulkCreateProperties(type: PropertyType, quantity: number) {
    await PropertyService.bulkCreateProperty({type, quantity})
    await this.getAllProperties()
  }

  @action
  async updateProperty(id: number, property: IProperty) {
    const response = await PropertyService.updateProperty(id, property)

    const updatedPropertyIndex = this.properties.findIndex(property => property.id === id)

    this.properties[updatedPropertyIndex] = response.data
  }

  @action
  async deleteProperty(id: number) {
    await PropertyService.deleteProperty(id)

    this.properties = this.properties.filter(property => property.id !== id)
  }
}

const storeInstance = new PropertyStore()
export default storeInstance
